import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './services/auth/auth.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { CordovaService } from './services/cordova.service';
import { DeviceService, GeolocationService, CameraService, FirebaseService, NetworkInformationService, VibrationService, BatteryStatusService } from 'angular-cordova/plugin';
/* import { AuthService } from './services/service.index'; */


@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  /*   AuthService, */
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
      //Cordova Req		
      CordovaService,        
      DeviceService,
      GeolocationService,
      CameraService,
      FirebaseService,
      NetworkInformationService,
      VibrationService,
      BatteryStatusService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
  },
  /*   AuthService */
    
    // LandingPageService
  ]
})
export class SharedModule { }
