import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
/*   public authToken;
  private isAuthenticated = true; // Set this value dynamically */
  
  constructor(private router: Router,
    public _authService: AuthService) {}
/*   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isAuthenticated) {
      return true
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  } */


/*   -- valido
  canActivate(){
    console.log("AuthGuard ready!");
    if (this._authService.serviceIsLogged()){
      return true;
    }else{
      this.router.navigate(['/sessions/signin']);
      return false;
    }
    
  } */

  canActivate(){
    return true;
  }
}