import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICE } from '../../config/config';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  constructor(
    public http: HttpClient,public router: Router) { 
    console.log('SharedService Ready!');
    
  }


  


  ServiceF5(object: object) {
    let url = URL_SERVICE + '/F5';
    return this.http.post(url,object).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );    
  }


  ServiceF6(object: object) {
    let url = URL_SERVICE + '/F6';
    return this.http.post(url,object).pipe(
      map((res: any) => {
        //console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );    
  }

  

}
