import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatDatepickerModule, NativeDateModule, MAT_DATE_FORMATS } from '@angular/material';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

//Config
import { LOCALE } from '../app/config/config';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';

// import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
// import scss from 'highlight.js/lib/languages/scss';
// import typescript from 'highlight.js/lib/languages/typescript';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { rootRouterConfig } from './app.routing';
import { AppComponent } from './app.component';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { CordovaService } from './shared/services/cordova.service';
import { DeviceService, GeolocationService, CameraService, FirebaseService, NetworkInformationService, VibrationService, BatteryStatusService } from 'angular-cordova/plugin';
import { CordovaObjService } from 'angular-cordova/plugin/cordova-obj';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

registerLocaleData(localeEs, LOCALE ,localeExtraEs);

// export function hljsLanguages() {
//   return [
//     {name: 'typescript', func: typescript},
//     {name: 'scss', func: scss}
//   ];
// }
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {  
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    AmazingTimePickerModule,
    MatDatepickerModule,
    NativeDateModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    /* RouterModule.forRoot(rootRouterConfig, { useHash: false }) */
    RouterModule.forRoot(rootRouterConfig, { useHash: true })
  ],
  declarations: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: LOCALE_ID, useValue: LOCALE },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
            //Cordova Req        
            CordovaService,        
            DeviceService,
            GeolocationService,
            CameraService,
            FirebaseService,
            NetworkInformationService,
            VibrationService,
            BatteryStatusService,  
            CordovaObjService,
    // { provide: HIGHLIGHT_OPTIONS, useValue: { languages: hljsLanguages } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }