import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs";
import { isNumeric } from "rxjs/util/isNumeric"

@Injectable({
  providedIn: "root"
})
export class SearchService {
  public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public searchTerm$: Observable<string> = this.searchTerm.asObservable();

  constructor() {}

  prepareRes(data:any,where?:any,group?:string){
    let ret = [];
    let aux = data;        

    console.log(typeof(where));
    
    if(where === undefined || where == "" ){
      aux = data;
    }else if(typeof(where) == 'object'){
      aux = data;
      for (let field in where) {
        if (typeof(where[field]) == 'string'){
          aux = aux.filter(i => (i[field].toUpperCase().search(where[field].toUpperCase())>=0));
        }else if (typeof(where[field]) == 'number'){
          aux = aux.filter(i => (i[field] == where[field]));
        }
        
      }

    }else if(typeof(where) == 'number' || isNumeric(where)){
        
      aux = data.filter(item => {
        for (let fld in item) {        
            if (typeof(item[fld]) == 'number'){
              if (item[fld] == where){
                return true;
              }
            }
        }
       });

    }else if(typeof(where) == 'string'){    
      aux = data.filter(item => {
        for (let fld in item) {        
            //console.log(item[fld]);
            if (typeof(item[fld]) == 'string' || typeof(item[fld]) == 'object'){
              if (item[fld].toUpperCase().search(where.toUpperCase())>=0){
                return true;
              }
            }
        }
       });

    }else{
      aux = data;
    }

    if (group === undefined || group == ""){
      ret = aux;
    }else{
      let gro = new Set(aux.map(item => item[group]));
      gro.forEach(g => 
        ret.push({
          Group: g, 
          Items:  aux.filter(i => i[group] === g)
        }
      ));         
    }

    return ret;
  }

}
